import { createApp } from 'vue';
import favoriteBtn from '../../components/relationships/favorite-btn.vue';

document.addEventListener('DOMContentLoaded', () => {
	let templates = document.querySelectorAll("[data-vue='favorite-btn']");
	for (let el of templates) {
		const initialData = JSON.parse(el.getAttribute('data'));
		const app = createApp(favoriteBtn, { userId: initialData['userId'], isFavorite: initialData['isFavorite'] });
		app.mount(el);
	}
});
