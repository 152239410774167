<template>
    <div v-if="isFavoriteUser" @click="unfavorite" class="favorite-btn active">
        <i class="fas fa-star"></i>
        <span>きになる</span>
    </div> 
    <div v-else @click="favorite" class="favorite-btn"> 
        <i class="far fa-star"></i>
        <span>きになる</span>
    </div>
</template>

<script>
import axios from 'packs/axios'

export default {
   props: ["userId","isFavorite"],
  data() {
    return {
      isFavoriteUser: false
    };
  },
  created() {
    this.isFavoriteUser = this.isFavorite
  },
  methods: {
    favorite: async function() {
        const res = await axios.post('/api/v1/relationships/favorites', { user_id: this.userId })
        if (res.status !== 200) { process.exit() }
        this.isFavoriteUser = true
    },
    unfavorite: async function() {
        const res = await axios.delete(`/api/v1/relationships/favorites`, {data: {user_id: this.userId }})
        if (res.status !== 200) { process.exit() }
        this.isFavoriteUser = false
    },
  }
}
</script>

<style lang="scss">
  .mainvisual-carouse-images{
    margin-bottom: 20px;
    @media(max-width: 567px){
      margin-bottom: 12px;
    }
  }
</style>
